@import 'grid';
@import 'theme';

/* Importing Bootstrap SCSS file. */
@import 'node_modules/bootstrap/scss/bootstrap';
@import "~bootstrap-icons/font/bootstrap-icons.css";

///* Importing Swiper SCSS file. */
@import '~swiper/swiper-bundle.css';

@import 'typography';
@import 'cards';

:root {
  --mouse-x: 85vw;
  --mouse-y: 85vh;
  --color-background: 255, 255, 255;
  --color-foreground: 0, 0, 0;
  --highlight-color: #{$pink};
}

html,
body {
  height: 100%;
}

html {
  overflow-y: scroll;
}

.pointer {
  cursor: pointer;
}

.mt-6 {
  margin-top: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.arrow-cursor {
  fill: var(--highlight-color)
}

.content {
  padding-top: 56px;
  position: relative;
  z-index: 1;
}

.btn-highlight {
  background-color: var(--highlight-color) !important;
}

.page-title-description {
  margin-top: 9rem;
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .page-title-description {
    margin-top: 0rem;
  }
}
