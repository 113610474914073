/* COLORS */
$blue: rgb(0, 0, 0);
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #f00c5e;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$black: rgb(0, 0, 0);
$white: rgb(255, 255, 255);

$body-bg: rgb(var(--color-background));
$body-color: rgb(var(--color-foreground));

/* FONTS */
$font-family-sans-serif: 'Oswald', sans-serif;
$line-height-base: 1.5;
$font-size-base: 1.25rem;


/* CARDS */
$card-border-width: 0;
$card-border-radius: 0;
$card-border-color: $white;

/* Links */
$link-color: $body-color;
$link-hover-color: var(--highlight-color);

/* FORMS */
$border-radius: 0;
