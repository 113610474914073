@import 'grid';

h1 {
  &.quote {
    font-size: 14rem;
    font-weight: bolder;
    line-height: 1;
    transition: 0.3s ease-in-out;

  }

  &.page-title {
    font-size: 10rem;
    font-weight: bolder;
  }
}

.underline-effect {
  background-image: linear-gradient(
      transparent calc(75% - 5px),
      var(--highlight-color) 5px
  );
  background-size: 100%;
  background-repeat: no-repeat;
  display: inline;
  transition: 0.5s ease;
}

span.highlight {
  color: var(--highlight-color);
}

span.tag {
  @extend .underline-effect;
  font-size: 2rem;
  font-weight: lighter;
}


@media only screen and (max-width: map-get($grid-breakpoints, "xl")) {
  h1.quote,
  h1.page-title {
    font-size: 10rem;
  }

  span.tag {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
  h1.quote,
  h1.page-title {
    font-size: 6rem;
  }

  span.tag {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
  h1.quote,
  h1.page-title {
    font-size: 3rem;
  }

  span.tag {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  h1.quote,
  h1.page-title {
    font-size: 3rem;
  }

  span.tag {
    font-size: 1rem;
  }
}
