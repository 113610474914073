/*
Grid System
*/
/* stylelint-disable unit-allowed-list */
$grid-breakpoints: (
  xs: 0,
  sm: 430px,
  md: 768px,
  lg: 1366px,
  xl: 1920px
) !default;
$container-max-widths: (
  sm: 400px,
  md: 720px,
  lg: 1200px,
  xl: 1600px
) !default;
/* stylelint-enable unit-allowed-list */


$grid-gutter-width: 20px;
